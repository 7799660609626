import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  FormLabel,
} from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'
import AdditionalRelativesList from './AdditionalRelativesList'

const AdditionalRelatives = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const otherRelatives = useSelector((state) => state.questionnaire.additionalRelativeInfos) || []
  const { setAdditionalRelatives } = useActions()

  const [modalOpened, setModalOpened] = useState(false)
  const [person, setPerson] = useState({})

  return (
    <>
      <FormLabel>
        Do you have any other relatives not previously mentioned that have been diagnosed with
        cancer or another condition?
      </FormLabel>
      <Button startIcon={<AddIcon />} onClick={() => setModalOpened(true)}>
        Add Other Relative
      </Button>
      {/* Modal Dialog */}
      <Dialog open={modalOpened} onClose={() => handleClose()}>
        <DialogTitle>Add Other Relative Info</DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? '80vw' : '35vw' }}>
          <Container>
            <Box display="flex" gap={3}>
              <TextFieldQuestion
                label="First Name"
                value={person.firstName || ''}
                handleChange={(value) => setPerson({ ...person, firstName: value })}
                multiline={false}
              />
              <TextFieldQuestion
                label="Last Name"
                value={person.lastName || ''}
                handleChange={(value) => setPerson({ ...person, lastName: value })}
                multiline={false}
              />
            </Box>
            <TextFieldQuestion
              label="Relationship to Patient and Parental Side (e.g. Mother’s Cousin)"
              value={person.relationshipToProband || ''}
              handleChange={(value) => setPerson({ ...person, relationshipToProband: value })}
              fullWidth
            />
            <TextFieldQuestion
              label="Medical Conditions (e.g. Breast cancer, colon cancer, etc.)"
              value={person.notes || ''}
              handleChange={(value) => setPerson({ ...person, notes: value })}
              fullWidth
            />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            endIcon={<AddIcon />}
            onClick={addPerson}
            disabled={!person.firstName && !person.lastName}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {/* Person List */}
      <AdditionalRelativesList persons={otherRelatives} />
    </>
  )

  function handleClose() {
    setModalOpened(false)
    setPerson({})
  }
  function addPerson() {
    setAdditionalRelatives({ value: person })
    handleClose()
  }
}

export default AdditionalRelatives
