import { PERSON_CONDITIONS } from '../../../../utils/ConditionTypes'
import { getPerson } from '../../../slices/questionnaireSlice/utils/helpers'

/**
 * Set condition property to a specific object in the list
 * ie. person.conditions: [{ isPresent: newValue }]
 * @type {(
 *  state: WritableDraft<{}>,
 *  action: { payload: { id: string, conditionId: string, value: any }},
 *  property: string,
 * )}
 */
const setPersonConditionProperty = (state, action, property) => {
  const { conditionId, value } = action.payload
  const person = getPerson(state, action)
  const conditions = person.conditions

  if (!conditions) throw new Error('setPersonCondition must be run first')

  const index = conditions.findIndex(({ id }) => id === conditionId)

  if (index < 0) throw new Error('Matching condition not found on person')

  // modify existing object in list
  conditions[index] = {
    ...conditions[index],
    [property]: value,
  }
}

export default {
  /**
   * Set/Add condition object to the list
   * ie. person.conditions: [{ id: string, type: string }]
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, conditionId: string }}
   * )}
   */
  setPersonConditions(state, action) {
    const { conditionId } = action.payload
    const person = getPerson(state, action)
    const condition = PERSON_CONDITIONS.find(({ id }) => id === conditionId)

    if (!condition) {
      throw new Error('Unable to find the condition.')
    }

    const newCondition = {
      id: condition.id,
      label: condition.label,
      type: condition.type,
      isPresent: 'Y',
      qualifiers: [],
    }

    if (!person.conditions) {
      // create list and new object
      person.conditions = [newCondition]

      return
    }

    // check if object already exists
    if (person.conditions.findIndex(({ id }) => id === conditionId) < 0) {
      // create new object in existing list
      person.conditions = [...person.conditions, newCondition]
    }
  },
  /**
   * Set condition isPresent to specified condition object
   * ie. person.conditions[id]: {isPresent: string}
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, conditionId: string, value: string }}
   * )}
   */
  setPersonConditionIsPresent(state, action) {
    setPersonConditionProperty(state, action, 'isPresent')
  },
  /**
   * Set condition notes to specified condition object
   * ie. person.conditions[id]: {notes: string}
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, conditionId: string, value: string }}
   * )}
   */
  setPersonConditionNotes(state, action) {
    setPersonConditionProperty(state, action, 'notes')
  },
  /**
   * Add a treatment to the specified condition object
   * ie. person.conditions[].treatments[]: {ageAtTreatmentPerformed: string}
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, conditionId: string, treatmentType: string, value: object }}
   * )}
   */
  setPersonConditionTreatments(state, action) {
    const { conditionId, treatmentType, value, isPerformed } = action.payload
    const person = getPerson(state, action)
    const condition = person.conditions.find(({ id }) => id === conditionId)

    let treatment = { type: treatmentType, ...value }

    if (isPerformed) {
      treatment = { ...treatment, isPerformed }
    }

    if (!condition.treatments) {
      condition.treatments = [treatment]
    } else {
      const index = condition.treatments.findIndex(({ type }) => type === treatmentType)

      if (index < 0) {
        condition.treatments = [...condition.treatments, treatment]
      } else {
        condition.treatments[index] = { ...condition.treatments[index], ...value, isPerformed }
      }
    }
  },
  /**
   * Add a qualifier to the specified condition object
   * ie. person.conditions[].qualifiers[]: {label: string, type: string}
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, conditionId: string, label: string, type: string }}
   * )}
   */
  setPersonConditionQualifiers(state, action) {
    const { conditionId, label, type } = action.payload
    const person = getPerson(state, action)
    const condition = person.conditions.find(({ id }) => id === conditionId)

    const qualifier = { label, type }

    if (!condition.qualifiers) {
      condition.qualifiers = [qualifier]
    } else {
      const index = condition.qualifiers.findIndex(({ label: _label }) => _label === label)

      if (index < 0) {
        condition.qualifiers = [...condition.qualifiers, qualifier]
      } else {
        condition.qualifiers[index] = { ...condition.qualifiers[index], type, label }
      }
    }
  },
  /**
   * Remove a qualifier to the specified condition object
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, conditionId: string, label: string }}
   * )}
   */
  removePersonConditionQualifiers(state, action) {
    const { conditionId, label } = action.payload
    const person = getPerson(state, action)
    const index = person.conditions
      .find(({ id }) => id === conditionId)
      ?.treatments?.findIndex(({ label: _label }) => _label === label)

    if (index < 0) {
      throw new Error('Matching qualifier not found on person')
    } else {
      person.conditions[index].qualifiers.splice(index, 1)
    }
  },
  /**
   * Remove a treatment from the specified condition object
   * ie. person.conditions[].treatments[]
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, conditionId: string, treatmentType: string }}
   * )}
   */
  removePersonConditionTreatments(state, action) {
    const { conditionId, treatmentType } = action.payload
    const person = getPerson(state, action)
    const index = person.conditions
      .find(({ id }) => id === conditionId)
      ?.treatments?.findIndex(({ type }) => type === treatmentType)

    if (index < 0) {
      throw new Error('Matching treatment not found on person')
    } else {
      person.conditions[index].treatments.splice(index, 1)
    }
  },
  /**
   * Remove condition object form the list
   * ie. person.conditions: [{ id: string, type: string }]
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, conditionId: string }}
   * )}
   */
  removePersonConditions(state, action) {
    const { conditionId } = action.payload
    const person = getPerson(state, action)

    person.conditions = person.conditions.filter(({ id }) => id !== conditionId)
  },
  /**
   * Special cleanup function for heart conditions
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string }})}
   */
  removePersonHeartConditions(state, action) {
    const person = getPerson(state, action)

    person.conditions = person.conditions.filter(({ type }) => type !== 'heartConditions')
  },
}
