import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson, renderContent } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import CheckBoxList from '../../widgets/CheckBoxList'
import ConditionalSection from '../../widgets/ConditionalSection'
import { SubQuestionLabel } from '../../widgets/FormLabel'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const Genes = ({ id, geneTests, label }) => {
  const genes = [
    { id: 'brca1', name: 'BRCA1' },
    { id: 'brca2', name: 'BRCA2' },
    { id: 'palb2', name: 'PALB2' },
    { id: 'chek2', name: 'CHEK2' },
    { id: 'atm', name: 'ATM' },
    { id: 'bard1', name: 'BARD1' },
    { id: 'rad51c', name: 'RAD51C' },
    { id: 'rad51d', name: 'RAD51D' },
    { id: 'brip1', name: 'BRIP1' },
  ]

  const { removePersonGeneTests, setPersonGeneTests } = useActions()

  const handleGeneChange = (gene) => () => {
    const geneIndex = geneTests.findIndex(({ nameOfGeneTest }) => gene.id === nameOfGeneTest)

    if (geneIndex >= 0) {
      if (geneTests.length) {
        removePersonGeneTests({ id, type: gene.id })
      }
    } else {
      setPersonGeneTests({ id, type: gene.id })
    }
  }

  return (
    <FormControl key="gtg">
      <SubQuestionLabel>{label}</SubQuestionLabel>
      <CheckBoxList>
        {genes.map((gene) => {
          const isChecked = !!geneTests.find(({ nameOfGeneTest }) => nameOfGeneTest === gene.id)

          return (
            <FormControlLabel
              label={gene.name}
              control={<Checkbox checked={isChecked} onChange={handleGeneChange(gene)} />}
              key={gene.id}
            ></FormControlLabel>
          )
        })}
      </CheckBoxList>
    </FormControl>
  )
}

const GeneticTesting = ({ id, patient, config }) => {
  const { setPersonGeneticTesting, removeAllPersonGeneTests, removePersonGeneticTesting } =
    useActions()
  const localization = useLocalization()
  const { performed = '', description = '' } =
    useSelector((state) => get(state, [...pathToPerson(id), 'geneticTesting'])) || {}
  const geneTests =
    useSelector((state) => get(state, [...pathToPerson(id), 'cancerRiskData', 'geneTests'])) || []

  return (
    <ConditionalSection
      label={getLabel(localization, paths.GENETIC_TESTING_PERFORMED, patient)}
      conditionState={performed}
      onConditionStateChange={(value) => {
        if (value !== performed) {
          setPersonGeneticTesting({ id, type: 'performed', value })
        }
      }}
      cleanUpChildQuestions={() => {
        if (description) {
          removePersonGeneticTesting({ id, type: 'description' })
        }

        if (geneTests.length) {
          removeAllPersonGeneTests({ id })
        }
      }}
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
    >
      {renderContent({
        renderMap: {
          geneticTestLocation: (
            <TextFieldQuestion
              key="gtl"
              label={getLabel(localization, paths.GENETIC_TESTING_DESCRIPTION, patient)}
              value={description}
              handleChange={(value) => setPersonGeneticTesting({ id, type: 'description', value })}
              subQuestion
              fullWidth
            />
          ),
          geneticTestGene: (
            <Genes
              key="gtg"
              id={id}
              geneTests={geneTests}
              label={getLabel(localization, paths.POSITIVE_GENES_LABEL, patient)}
            />
          ),
        },
        config,
      })}
    </ConditionalSection>
  )
}

export default GeneticTesting
